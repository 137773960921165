import { Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { useAuth } from "./providers/AuthContext";
import { Login } from "./pages/Authentication/Login";
import { Layout } from "./components/common/Layout";
import { ForgotPasswordEmail } from "./pages/Authentication/ForgotPasswordEmail";
import { ForgotPasswordVerifiedEmail } from "./pages/Authentication/ForgotPasswordVerifiedEmail";
import { ForgotPasswordOtpVerify } from "./pages/Authentication/ForgotPasswordOtpVerify";
import { ResetPassword } from "./pages/Authentication/ResetPassword";
import { PrivacyPolicy } from "./pages/PrivacyPolicy/PrivacyPolicy";
function App() {
  const { isLogged } = useAuth();

  return (
    <>
      {!isLogged ? (
        <Routes>
          <Route path="/*" Component={Login} />
          <Route path="/forgotPasswordEmail" Component={ForgotPasswordEmail} />
          <Route
            path="/forgotPasswordOtp"
            Component={ForgotPasswordOtpVerify}
          />
          <Route
            path="/forgotPasswordVerifiedEmail"
            Component={ForgotPasswordVerifiedEmail}
          />
          <Route path="/resetPassword" Component={ResetPassword} />
          <Route path="/privacyPolicy" Component={PrivacyPolicy} />
        </Routes>
      ) : (
        <Routes>
          <Route path="/*" Component={Layout} />
        </Routes>
      )}
    </>
  );
}

export default App;
